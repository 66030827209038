// 引入封装的第一层axios接口
import req from "@/utils/req.js"

// 获取轮播图片
export const sceneSlide_api = (params)=>{
  return req.get("api/resource-manager/client/picture/site_page",params)
}
// 获取场景列表
export const getSceneList_api = (params)=>{
  return req.get("api/resource-manager/client/site/page",params)
}

// 获取团队预约场景列表
export const getTeamSceneList_api = (params)=>{
  return req.get("api/resource-manager/client/site/list",params)
}

// 获取场景详情
export const getSceneInfo_api = (params)=>{
  return req.get("api/resource-manager/client/site/detail",params)
}
// 团队预约
export const reservegroup_api = (params)=>{
  return req.post("api/resource-select/reservegroup/save",params)
}
// 取消团队预约
export const removeReservegroup_api = (params)=>{
  return req.post("api/resource-select/reservegroup/remove",params)
}