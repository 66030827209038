<template>
  <div>
    <div :style="biStyle"></div>
    <el-card class="containar">
      <h3>
        <span>团队预约</span>
        <button
          @click="handleDescs('create_time')"
          :class="descs == 'create_time' ? 'btn active' : 'btn'"
        >
          最新⬇
        </button>
        <button
          @click="handleDescs('site_heat')"
          :class="descs == 'site_heat' ? 'btn active' : 'btn'"
        >
          最热⬇
        </button>
      </h3>
      <div v-if="siteArr.length > 0" class="list">
        <el-card
          v-for="(item, i) in siteArr"
          :key="i"
          :body-style="{ padding: '0px' }"
        >
          <el-container>
            <el-aside width="340px">
              <el-image
                style="width: 300px; height: 150px"
                :src="item.sitePicture"
                fit="cover"
              ></el-image>
            </el-aside>
            <el-main>
              <h4 class="title">{{ item.siteName }}</h4>
              <p class="intro">{{ item.siteIntroduction }}</p>
              <div class="handle">
                <div class="left">
                  <p class="icon">
                    <i class="el-icon-star-off"> </i> 热度：{{
                      item.timer ? item.timer : 0
                    }}
                  </p>
                  <p class="icon">
                    <i class="el-icon-s-custom"> </i> 限制人数：{{
                      item.siteLimitNumber
                    }}
                  </p>
                  <p v-if="item.siteAddress" class="icon">
                    <i class="el-icon-location-outline"> </i> 场景地址：{{
                      item.siteAddress
                    }}
                  </p>
                </div>
                <div class="right">
                  <button @click="handleAppointment(item)" class="btn">
                    立即预约
                  </button>
                </div>
              </div>
            </el-main>
          </el-container>
        </el-card>
        <el-pagination
          v-if="total > pageSize"
          @current-change="changePage"
          background
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <EmptyBox class="empty" v-else>暂无可约团队</EmptyBox>
    </el-card>
  </div>
</template>

<script>
import bc from "@/assets/img/sceneInfoBc.jpg";
import EmptyBox from "@/components/empty.vue";
import { getTeamSceneList_api } from "@/api/scene.js";
import { set } from "@/utils/local.js";
export default {
  components: { EmptyBox },
  data() {
    return {
      biStyle: {
        // 导航栏下面的通栏背景图
        height: "280px",
        backgroundImage: "url(" + bc + ")",
      },
      siteArr: [], // 场景数据
      // descs: "site_heat", // 排序方式
      descs: "create_time", // 排序方式
      currentPage: 1, // 当前页
      pageSize: 5, // 每页条数
      total: 0, // 总条数
    };
  },
  methods: {
    async getTeamSceneList() {
      let { data, code } = await getTeamSceneList_api({
        query: {
          tenantId: this.$tenantId,
          current: this.currentPage,
          size: this.pageSize,
          descs: this.descs,
        },
      });
      if (code == 200) {
        this.siteArr = data.records;
        this.total = data.total;
      }
    },
    // 立即预约
    handleAppointment(item) {
      set("scene", item);

      if (!this.$store.state.isLogin) {
        // 如果没登录
        this.$router.push("/login?redirect=/scene/sceneAdd?siteId=" + item.id);
      } else if (
        this.$store.state.userInfo.roleName === "学生" ||
        this.$store.state.userInfo.roleName === "student"
      ) {
        // 是学生
        this.$confirm(
          "检测到你的帐号为学生！团队只支持教师帐号预约！你可以去看看有哪些课程可以预约?",
          "提示",
          {
            confirmButtonText: "去看看课程",
            cancelButtonText: "留在当前页",
            type: "warning",
          }
        ).then(() => {
          this.$router.push("/course");
        });
      } else {
        // 教师
        this.$router.push("/scene/sceneAdd?siteId=" + item.id);
      }
    },
    // 切换排序方式
    handleDescs(descs) {
      this.descs = descs;
      this.getTeamSceneList();
    },
    // 切换页码
    changePage(currentPage) {
      this.currentPage = currentPage;
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.getTeamSceneList();
    },
  },
  created() {
    this.getTeamSceneList();
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.containar {
  margin: -100px auto 100px;
  padding: 40px;
  h3 {
    span {
      font-weight: 700;
      font-size: 20px;
      color: @title-color;
    }
    .btn {
      margin-left: 20px;
      padding: 2px 15px;
      background-color: #ddd;
      &.active {
        background-color: @btn-color;
      }
    }
  }
  .list {
    margin-bottom: 100px;
    .el-card {
      margin: 30px 0;
      .el-aside {
        padding: 20px;
      }
      .el-main {
        padding: 20px 20px 20px 0;
        line-height: 2em;
        font-size: 14px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 18px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          letter-spacing: 2px;
        }
        .intro {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          letter-spacing: 2px;
        }
        .handle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            white-space: nowrap;
            overflow: hidden;
            .icon {
              color: #888;
            }
          }
          .right {
            .btn {
              margin-left: 20px;
              padding: 8px 20px;
              border-radius: 20px;
              background-color: @btn-color;
            }
          }
        }
      }
    }
    // 分页
    .el-pagination {
      display: flex;
      justify-content: center;
    }
  }
  .empty {
    margin: 100px 0;
  }
}
</style>